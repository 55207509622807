<template>
  <div class="image-input" :class="{ have_img: url || isFile(image) }">
    <div class="image-input_label">{{ label }}:</div>

    <FormulateInput
      :key="url"
      type="image"
      class="image-input_form"
      :value="value"
      :uploader="fakeUploader"
      @change="onChangeInput"
      :disabled="!editable"
      :validation="`mime:${imageTypes}`"
      v-bind:class="editableClass"
      :validation-messages="{
        mime: validationText,
      }"
    >
      <template v-slot:uploadAreaMask>
        <div v-if="!imageURL" class="image-input-extra">
          <span class="help-text">{{ helpText }}</span>
          <span class="phrase">{{ separatorText }}</span>
          <Button
            :label="buttonText"
            :bg-color="colorsHtml.navy"
            variant="btn-purple"
            font-weight="700"
          />
        </div>
      </template>
      <template v-slot:file>
        <ul v-if="imageURL" class="formulate-files">
          <li data-has-preview="true">
            <div class="formulate-file">
              <div class="formulate-file-image-preview">
                <img :src="imageURL" alt="" />
              </div>
              <div :title="image.name" class="formulate-file-name">
                <template v-if="image.name">
                  {{ image.name }}
                </template>
                <template v-else-if="url">
                  {{ url.split("_")[1] }}
                </template>
              </div>
              <a
                v-if="url"
                :href="url"
                target="_blank"
                class="full-image-preview"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 8.67568V16.4595C18 17.3108 17.3108 18 16.4595 18H1.54054C0.689189 18 0 17.3108 0 16.4595V1.54054C0 0.689189 0.689189 0 1.54054 0H9.32432C9.63649 0 9.89189 0.255405 9.89189 0.567568C9.89189 0.87973 9.63649 1.13514 9.32432 1.13514H2.18919C1.60946 1.13514 1.13514 1.60946 1.13514 2.18919V15.8108C1.13514 16.3905 1.60946 16.8608 2.18919 16.8649H15.8108C16.3905 16.8649 16.8608 16.3905 16.8649 15.8108V8.67568C16.8649 8.36351 17.1203 8.10811 17.4324 8.10811C17.7446 8.10811 18 8.36351 18 8.67568Z"
                  />
                  <path
                    d="M17.9992 0.243243V4.78378C17.9992 5.09595 17.7438 5.35135 17.4317 5.35135C17.1195 5.35135 16.8641 5.09595 16.8641 4.78378V1.93784L16.1709 2.63108L10.329 8.47297C10.2236 8.56622 10.0898 8.61892 9.94789 8.61892C9.78978 8.61892 9.63978 8.55405 9.53437 8.43649C9.33167 8.22162 9.33167 7.88514 9.53032 7.66622L15.3722 1.82838L16.0614 1.13514H13.2155C12.9033 1.13514 12.6479 0.87973 12.6479 0.567568C12.6479 0.255405 12.9033 0 13.2155 0H17.756C17.8898 0 17.9992 0.109459 17.9992 0.243243Z"
                  />
                </svg>
              </a>
              <span class="formulate-remove-container">
                <div @click="imageRemoved" class="formulate-file-remove"></div>
              </span>
            </div>
          </li>
        </ul>
      </template>
    </FormulateInput>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import colorsHtml from "/colors.config.json";
library.add(faExternalLinkAlt);

export default {
  name: "ImageInput",
  components: {
    Button,
  },
  props: {
    label: {
      type: String,
    },
    url: {
      type: String,
    },
    name: {
      type: String,
    },
    helpText: {
      type: String,
      default: "Drag & Drop a png or jpg to upload",
    },
    separatorText: {
      type: String,
      default: "or",
    },
    buttonText: {
      type: String,
      default: "Browse files",
    },
    imageTypes: {
      type: String,
      default: "image/jpeg,image/png,image/svg+xml",
    },
    validationText: {
      type: String,
      default: "Allowed image types: jpeg, png. Best size: 1024px x 768px",
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      colorsHtml: colorsHtml,
      imageURL: "",
      image: {},
      changed: false,
    };
  },
  computed: {
    value() {
      if (this.url) {
        let arr = [{ url: this.url }];
        return arr;
      }

      return "";
    },
    editableClass() {
      if (!this.editable) {
        return "not-editable";
      }
      return "";
    },
  },
  mounted() {
    this.imageURL = this.url;
  },
  methods: {
    isFile(obj) {
      return obj instanceof File;
    },
    onChangeInput() {
      if (this.editable) {
        this.changed = true;
      }
    },
    fakeUploader(image) {
      if (this.editable) {
        if (this.changed) {
          this.imageURL = URL.createObjectURL(image);
          this.image = image;
          this.imageChanged();
        }
        return Promise.resolve({});
      }
    },
    imageRemoved() {
      if (this.editable) {
        this.image = {};
        this.imageURL = "";

        this.imageChanged();
        this.changed = false;
      }
    },
    imageChanged() {
      this.$emit("image-changed", this.image);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

button {
  border: none;
}
.image-input {
  width: 100%;

  &.have_img {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .image-input_label {
      margin-bottom: 0;
      margin-right: 15px;
    }
  }

  ::v-deep .not-editable {
    pointer-events: none;
  }

  ::v-deep .not-editable .formulate-file-remove {
    display: none;
  }

  ::v-deep {
    .formulate-input-element {
      display: flex;
    }

    .formulate-input-upload-area {
      padding: 0 0 0px !important;
      flex: 1 1;
      position: relative;

      .image-input-extra {
        background-color: $background_2;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 15px;

        .help-text {
          color: #2d2f31;
          font-size: 1.063rem;
          font-weight: 500;
          display: flex;
          align-items: center;

          &::before {
            -webkit-mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 612.002 612.002" style="enable-background:new 0 0 612.002 612.002;" xml:space="preserve"><path d="M522.878,241.337c-16.836-56.94-69.525-98.491-131.923-98.491c-9.942,0-19.631,1.065-28.974,3.066 c-25.17-32.322-64.453-53.114-108.593-53.114c-75.972,0-137.563,61.59-137.563,137.565c0,0.946,0.017,1.889,0.036,2.83 C49.781,246.45,0,304.801,0,374.783c0,79.762,64.661,144.421,144.424,144.421h323.157c79.762,0,144.421-64.659,144.421-144.421 C612,314.612,575.201,263.043,522.878,241.337z M371.918,321.869c-4.381,4.382-10.125,6.574-15.869,6.574 c-5.744,0-11.486-2.191-15.869-6.572l-11.733-11.733v105.094c0,12.395-10.047,22.442-22.444,22.442 c-12.395,0-22.444-10.047-22.444-22.442V310.137l-11.734,11.734c-8.765,8.763-22.972,8.763-31.739-0.002 c-8.765-8.765-8.763-22.974,0-31.739l50.047-50.043c8.766-8.763,22.974-8.762,31.739,0l50.045,50.043 C380.681,298.894,380.683,313.105,371.918,321.869z"/></svg>');
            mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 612.002 612.002" style="enable-background:new 0 0 612.002 612.002;" xml:space="preserve"><path d="M522.878,241.337c-16.836-56.94-69.525-98.491-131.923-98.491c-9.942,0-19.631,1.065-28.974,3.066 c-25.17-32.322-64.453-53.114-108.593-53.114c-75.972,0-137.563,61.59-137.563,137.565c0,0.946,0.017,1.889,0.036,2.83 C49.781,246.45,0,304.801,0,374.783c0,79.762,64.661,144.421,144.424,144.421h323.157c79.762,0,144.421-64.659,144.421-144.421 C612,314.612,575.201,263.043,522.878,241.337z M371.918,321.869c-4.381,4.382-10.125,6.574-15.869,6.574 c-5.744,0-11.486-2.191-15.869-6.572l-11.733-11.733v105.094c0,12.395-10.047,22.442-22.444,22.442 c-12.395,0-22.444-10.047-22.444-22.442V310.137l-11.734,11.734c-8.765,8.763-22.972,8.763-31.739-0.002 c-8.765-8.765-8.763-22.974,0-31.739l50.047-50.043c8.766-8.763,22.974-8.762,31.739,0l50.045,50.043 C380.681,298.894,380.683,313.105,371.918,321.869z"/></svg>');
            mask-repeat: no-repeat;
            background-color: #68686d;
            content: "";
            margin-right: 15px;
            width: 32px;
            height: 32px;
            transition: all 300ms ease-out;
          }
        }

        .phrase {
          margin-left: 20px;
          margin-right: 20px;
          font-weight: 600;
          font-size: 1rem;
          color: #bbbbbb;
        }
        .btn {
          padding: 10px 20px;
        }
      }
      .formulate-files {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: $background;

        .formulate-file {
          border: none;
        }
        .formulate-file-name {
          display: flex;
          align-items: center;
          color: $dark;
          font-size: 0.875rem;
          font-weight: 600;
          margin: 0;
          padding: 0 10px;
        }
        .full-image-preview {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 35px;
          height: 35px;
          margin-right: 1rem;
          background-color: #e9edee;
          cursor: pointer;
          border-radius: 50%;

          svg {
            fill: gray;
            transition: all 0.1s ease-in-out;
            width: 15px;

            &:hover {
              transform: scale(1.1);
            }
          }
        }
        .formulate-remove-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: #e9edee;

          .formulate-file-remove {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &:hover {
        .image-input-extra {
          .help-text {
            &::before {
              background-color: $focus_color;
            }
          }
        }
      }
      input {
        display: block !important;
      }
    }
  }

  &_label {
    font-family: "Lato";
    font-size: 1.125rem;
    letter-spacing: 0.02em;
    text-align: left;
    color: $dark;
    margin-bottom: 15px;
    font-weight: bold;
    text-transform: inherit;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &_form {
    min-width: 110px;
    margin-bottom: 0;
  }
}
</style>
